// Place all the styles related to the landing controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
// Media Queries in Sass 3.2
//
// These mixins make media queries a breeze with Sass.
// The media queries from mobile up until desktop all
// trigger at different points along the way
//
// And important point to remember is that and width
// over the portrait width is considered to be part of the
// landscape width. This allows us to capture widths of devices
// that might not fit the dimensions exactly. This means the break
// points are seamless.

$mq-mobile-portrait: 320px !default;
$mq-mobile-landscape: 480px !default;
$mq-tablet-portrait: 768px !default;
$mq-tablet-landscape: 1024px !default;
$mq-desktop: 1382px !default;

// Both portrait and landscape
@mixin mobile-only {
	@media (max-width: $mq-mobile-landscape) {
		@content;
	}
}

// Everything up to and including the portrait width of the phone
// Since it's the smallest query it doesn't need a min
@mixin mobile-portrait-only {
	@media (max-width: $mq-mobile-portrait) {
		@content;
	}
}

// Everything up to and including the mobile portrait
@mixin mobile-portrait-and-below {
	@media (max-width: $mq-mobile-portrait) {
		@content;
	}
}

// Everything above and including the mobile portrait
@mixin mobile-portrait-and-up {
	@media (min-width: $mq-mobile-portrait) {
		@content;
	}
}

// Everthing larger than a portrait mobile up until mobile landscape
@mixin mobile-landscape-only {
	@media only screen and (min-width: $mq-mobile-portrait + 1) and (max-width: $mq-mobile-landscape) {
		@content;
	}
}

// Everything up to and including the mobile landscape width
@mixin mobile-landscape-and-below {
	@media only screen and (max-width: $mq-mobile-landscape) {
		@content;
	}
}

// Everything above and including the mobile landscape width
@mixin mobile-landscape-and-up {
	@media only screen and (min-width: $mq-mobile-portrait + 1) {
		@content;
	}
}

// Both the portrait and landscape width of the tablet
// Larger than a landscape mobile but less than or equal to a landscape tablet
@mixin tablet-only {
	@media only screen and (min-width: $mq-mobile-landscape + 1) and (max-width: $mq-tablet-landscape) {
		@content;
	}
}

// Everything larger than mobile landscape up until the portrait width of the tablet
@mixin tablet-portrait-only {
	@media only screen and (min-width: $mq-mobile-landscape + 1) and (max-width: $mq-tablet-portrait) {
		@content;
	}
}

// Everything below and including the portrait width of the tablet
@mixin tablet-portrait-and-below {
	@media only screen and (max-width: $mq-tablet-portrait) {
		@content;
	}
}

// Everything above and including the portrait width of the tablet
@mixin tablet-portrait-and-up {
	@media only screen and (min-width: $mq-mobile-landscape + 1) {
		@content;
	}
}

// Larger than portrait but less than or equal to the landscape width
@mixin tablet-landscape-only {
	@media only screen and (min-width: $mq-tablet-portrait + 1) and (max-width: $mq-tablet-landscape) {
		@content;
	}
}

// Up to and including the tablet landscape
@mixin tablet-landscape-and-below {
	@media only screen and (max-width: $mq-tablet-landscape) {
		@content;
	}
}

// Everything larger than portrait tablet
@mixin tablet-landscape-and-up {
	@media only screen and (min-width: $mq-tablet-portrait + 1) {
		@content;
	}
}

// Everything larger than a landscape tablet
@mixin desktop-and-up {
	@media only screen and (min-width: $mq-tablet-landscape + 1) {
		@content;
	}
}

// Everything below and including the desktop
@mixin desktop-and-below {
	@media only screen and (max-width: $mq-desktop) {
		@content;
	}
}

// Everything larger than a landscape tablet but less than or equal to the desktop
@mixin desktop-only {
	@media only screen and (min-width: $mq-tablet-landscape + 1) and (max-width: $mq-desktop) {
		@content;
	}
}

// Retina screens have a 1.5 pixel ratio, not 2
@mixin retina {
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
		@content;
	}
}

@mixin setIconColor($baseColor) {
	.result__icon {
		#faraday_blanco {
			fill: $baseColor;
		}
	}
}

.payment-container li {
	-webkit-filter: grayscale(70%);
	filter: grayscale(70%);
}

[class*="paymentmethod"] {
	background-repeat: no-repeat;
	display: inline-block;
	margin: 2px;
	overflow: hidden;
	text-indent: 100%;
	vertical-align: middle;
	white-space: nowrap;
}

.paymentmethod-account_money {
	background-position: 0 0;
}

.paymentmethod-visa,
.paymentmethod-visa.paymentmethod-large {
	background-position: 0 -49px;
}

.paymentmethod-debvisa {
	background-position: 0 -1248px;
}

.paymentmethod-debvisa.paymentmethod-large {
	background-position: 0 -1249px;
}

.paymentmethod-master,
.paymentmethod-master.paymentmethod-large {
	background-position: 0 -100px;
}

.paymentmethod-debmaster {
	background-position: 0 -1148px;
}

.paymentmethod-debmaster.paymentmethod-large {
	background-position: 0 -1149px;
}

.paymentmethod-amex,
.paymentmethod-amex.paymentmethod-large {
	background-position: 0 -150px;
}

.paymentmethod-bitcoin,
.paymentmethod-bitcoin.paymentmethod-large {
	background-position: 0 -250px;
}

.paymentmethod-diners,
.paymentmethod-diners.paymentmethod-large {
	background-position: 0 -200px;
}

.paymentmethod-naranja,
.paymentmethod-naranja.paymentmethod-large {
	background-position: 0 -300px;
}

.paymentmethod-nativa,
.paymentmethod-nativa.paymentmethod-large {
	background-position: 0 -350px;
}

.paymentmethod-tarshop,
.paymentmethod-tarshop.paymentmethod-large {
	background-position: 0 -400px;
}

.paymentmethod-cencosud,
.paymentmethod-cencosud.paymentmethod-large {
	background-position: 0 -450px;
}

.paymentmethod-cabal,
.paymentmethod-cabal.paymentmethod-large {
	background-position: 0 -500px;
}

.paymentmethod-debcabal {
	background-position: 0 -1298px;
}

.paymentmethod-debcabal.paymentmethod-large {
	background-position: 0 -1300px;
}

.paymentmethod-argencard,
.paymentmethod-argencard.paymentmethod-large {
	background-position: 0 -550px;
}

.paymentmethod-pagofacil,
.paymentmethod-pagofacil.paymentmethod-large {
	background-position: 0 -600px;
}

.paymentmethod-rapipago,
.paymentmethod-rapipago.paymentmethod-large {
	background-position: 0 -650px;
}

.paymentmethod-redlink,
.paymentmethod-redlink.paymentmethod-large {
	background-position: 0 -700px;
}

.paymentmethod-banelco,
.paymentmethod-banelco.paymentmethod-large {
	background-position: 0 -750px;
}

.paymentmethod-bapropagos,
.paymentmethod-bapropagos.paymentmethod-large {
	background-position: 0 -800px;
}

.paymentmethod-cargavirtual,
.paymentmethod-cargavirtual.paymentmethod-large {
	background-position: 0 -849px;
}

.paymentmethod-cordial {
	background-position: 0 -898px;
}

.paymentmethod-cordial.paymentmethod-large {
	background-position: 0 -900px;
}

.paymentmethod-cmr,
.paymentmethod-cmr.paymentmethod-large {
	background-position: 0 -949px;
}

.paymentmethod-cordobesa,
.paymentmethod-cordobesa.paymentmethod-large {
	background-position: 0 -998px;
}

.paymentmethod-mercadopago_cc {
	background-position: 0 -1047px;
}

.paymentmethod-mercadopago_cc.paymentmethod-large {
	background-position: 0 -1049px;
}

.paymentmethod-consumer_credits {
	background-position: 0 -1097px;
}

.paymentmethod-consumer_credits.paymentmethod-large {
	background-position: 0 -1098px;
}

.paymentmethod-maestro {
	background-position: 0 -1197px;
}

.paymentmethod-maestro.paymentmethod-large {
	background-position: 0 -1199px;
}

.paymentmethod-patagonia365 {
	background-position: 0 -1346px;
}

.paymentmethod-patagonia365.paymentmethod-large {
	background-position: 0 -1346px;
}

.paymentmethod-nevada {
	background-position: 0 -1396px;
}

.paymentmethod-nevada.paymentmethod-large {
	background-position: 0 -1393px;
}

.paymentmethod-cobroexpress {
	background-position: 0 -1448px;
}

.paymentmethod-cobroexpress.paymentmethod-large {
	background-position: 0 -1444px;
}

[class*="paymentmethod"] {
	background-image: url("https://http2.mlstatic.com/secure/org-img/ui/payment-methods/1.7.6/ar/payment-methods-default.png");
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
	[class*="paymentmethod"] {
		background-image: url("https://http2.mlstatic.com/secure/org-img/ui/payment-methods/1.7.6/ar/payment-methods-default@2x.png");
		background-size: 72px 1498px;
	}
}

.paymentmethod-account_money {
	height: 20px;
	width: 72px;
}

.paymentmethod-visa {
	width: 39px;
	height: 16px;
}

.paymentmethod-debvisa {
	width: 58px;
	height: 16px;
}

.paymentmethod-master {
	height: 26px;
	width: 30px;
}

.paymentmethod-debmaster {
	height: 26px;
	width: 49px;
}

.paymentmethod-amex {
	height: 17px;
	width: 17px;
}

.paymentmethod-diners {
	height: 16px;
	width: 62px;
}

.paymentmethod-bitcoin {
	height: 16px;
	width: 62px;
}

.paymentmethod-naranja {
	height: 22px;
	width: 16px;
}

.paymentmethod-nativa {
	height: 15px;
	width: 36px;
}

.paymentmethod-tarshop {
	width: 27px;
	height: 16px;
}

.paymentmethod-cencosud {
	height: 17px;
	width: 26px;
}

.paymentmethod-cabal {
	height: 17px;
	width: 17px;
}

.paymentmethod-debcabal {
	height: 17px;
	width: 17px;
}

.paymentmethod-argencard {
	height: 18px;
	width: 17px;
}

.paymentmethod-pagofacil,
.paymentmethod-redlink {
	height: 20px;
	width: 19px;
}

.paymentmethod-rapipago {
	height: 11px;
	width: 56px;
}

.paymentmethod-banelco {
	width: 46px;
	height: 16px;
}

.paymentmethod-bapropagos {
	width: 54px;
	height: 24px;
}

.paymentmethod-cargavirtual {
	height: 20px;
	width: 20px;
}

.paymentmethod-cordial {
	height: 21px;
	width: 30px;
}

.paymentmethod-cmr {
	height: 19px;
	width: 26px;
}

.paymentmethod-cordobesa {
	height: 10px;
	width: 40px;
}

.paymentmethod-mercadopago_cc {
	height: 20px;
	width: 30px;
}

.paymentmethod-consumer_credits {
	height: 20px;
	width: 74px;
}

.paymentmethod-maestro {
	height: 25px;
	width: 30px;
}

.paymentmethod-patagonia365 {
	height: 10px;
	width: 73px;
}

.paymentmethod-nevada {
	height: 20px;
	width: 25px;
}

.paymentmethod-cobroexpress {
	height: 16px;
	width: 58px;
}

.paymentmethod-large {
	background-image: url("https://http2.mlstatic.com/secure/org-img/ui/payment-methods/1.7.6/ar/payment-methods-large.png");
	margin: 5px;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
	.paymentmethod-large {
		background-image: url("https://http2.mlstatic.com/secure/org-img/ui/payment-methods/1.7.6/ar/payment-methods-large@2x.png");
		background-size: 116px 1494px;
		margin: 5px;
	}
}

.paymentmethod-account_money.paymentmethod-large {
	height: 31px;
	width: 76px;
}

.paymentmethod-visa.paymentmethod-large {
	height: 21px;
	width: 63px;
}

.paymentmethod-debvisa.paymentmethod-large {
	height: 21px;
	width: 66px;
}

.paymentmethod-master.paymentmethod-large {
	height: 36px;
	width: 46px;
}

.paymentmethod-debmaster.paymentmethod-large {
	height: 36px;
	width: 54px;
}

.paymentmethod-amex.paymentmethod-large {
	height: 27px;
	width: 26px;
}

.paymentmethod-diners.paymentmethod-large {
	height: 28px;
	width: 63px;
}

.paymentmethod-bitcoin.paymentmethod-large {
	height: 25px;
	width: 62px;
}

.paymentmethod-naranja.paymentmethod-large {
	height: 34px;
	width: 24px;
}

.paymentmethod-nativa.paymentmethod-large {
	width: 58px;
	height: 27px;
}

.paymentmethod-tarshop.paymentmethod-large {
	width: 24px;
	height: 27px;
}

.paymentmethod-cencosud.paymentmethod-large {
	width: 41px;
	height: 27px;
}

.paymentmethod-cabal.paymentmethod-large {
	width: 48px;
	height: 28px;
}

.paymentmethod-debcabal.paymentmethod-large {
	width: 48px;
	height: 28px;
}

.paymentmethod-argencard.paymentmethod-large {
	height: 28px;
	width: 28px;
}

.paymentmethod-pagofacil.paymentmethod-large,
.paymentmethod-redlink.paymentmethod-large {
	height: 31px;
	width: 31px;
}

.paymentmethod-rapipago.paymentmethod-large {
	height: 18px;
	width: 61px;
}

.paymentmethod-banelco.paymentmethod-large {
	height: 25px;
	width: 56px;
}

.paymentmethod-bapropagos.paymentmethod-large {
	width: 65px;
	height: 36px;
}

.paymentmethod-cargavirtual.paymentmethod-large {
	height: 33px;
	width: 32px;
}

.paymentmethod-cordial.paymentmethod-large {
	height: 25px;
	width: 38px;
}

.paymentmethod-cmr.paymentmethod-large {
	height: 31px;
	width: 31px;
}

.paymentmethod-cordobesa.paymentmethod-large {
	height: 25px;
	width: 42px;
}

.paymentmethod-mercadopago_cc.paymentmethod-large {
	height: 27px;
	width: 38px;
}

.paymentmethod-consumer_credits.paymentmethod-large {
	height: 32px;
	width: 116px;
}

.paymentmethod-maestro.paymentmethod-large {
	height: 38px;
	width: 44px;
}

.paymentmethod-patagonia365.paymentmethod-large {
	height: 16px;
	width: 76px;
}

.paymentmethod-nevada.paymentmethod-large {
	height: 28px;
	width: 38px;
}

.paymentmethod-cobroexpress.paymentmethod-large {
	height: 28px;
	width: 66px;
}

[class*="paymentmethod"] {
	background-repeat: no-repeat;
	display: inline-block;
	margin: 2px;
	overflow: hidden;
	text-indent: 100%;
	vertical-align: middle;
	white-space: nowrap;
}

.paymentmethod-account_money {
	background-position: 0 0;
}

.paymentmethod-visa,
.paymentmethod-visa.paymentmethod-large {
	background-position: 0 -49px;
}

.paymentmethod-debvisa {
	background-position: 0 -1248px;
}

.paymentmethod-debvisa.paymentmethod-large {
	background-position: 0 -1249px;
}

.paymentmethod-master,
.paymentmethod-master.paymentmethod-large {
	background-position: 0 -100px;
}

.paymentmethod-debmaster {
	background-position: 0 -1148px;
}

.paymentmethod-debmaster.paymentmethod-large {
	background-position: 0 -1149px;
}

.paymentmethod-amex,
.paymentmethod-amex.paymentmethod-large {
	background-position: 0 -150px;
}

.paymentmethod-bitcoin,
.paymentmethod-bitcoin.paymentmethod-large {
	background-position: 0 -250px;
}

.paymentmethod-diners,
.paymentmethod-diners.paymentmethod-large {
	background-position: 0 -200px;
}

.paymentmethod-naranja,
.paymentmethod-naranja.paymentmethod-large {
	background-position: 0 -300px;
}

.paymentmethod-nativa,
.paymentmethod-nativa.paymentmethod-large {
	background-position: 0 -350px;
}

.paymentmethod-tarshop,
.paymentmethod-tarshop.paymentmethod-large {
	background-position: 0 -400px;
}

.paymentmethod-cencosud,
.paymentmethod-cencosud.paymentmethod-large {
	background-position: 0 -450px;
}

.paymentmethod-cabal,
.paymentmethod-cabal.paymentmethod-large {
	background-position: 0 -500px;
}

.paymentmethod-debcabal {
	background-position: 0 -1298px;
}

.paymentmethod-debcabal.paymentmethod-large {
	background-position: 0 -1300px;
}

.paymentmethod-argencard,
.paymentmethod-argencard.paymentmethod-large {
	background-position: 0 -550px;
}

.paymentmethod-pagofacil,
.paymentmethod-pagofacil.paymentmethod-large {
	background-position: 0 -600px;
}

.paymentmethod-rapipago,
.paymentmethod-rapipago.paymentmethod-large {
	background-position: 0 -650px;
}

.paymentmethod-redlink,
.paymentmethod-redlink.paymentmethod-large {
	background-position: 0 -700px;
}

.paymentmethod-banelco,
.paymentmethod-banelco.paymentmethod-large {
	background-position: 0 -750px;
}

.paymentmethod-bapropagos,
.paymentmethod-bapropagos.paymentmethod-large {
	background-position: 0 -800px;
}

.paymentmethod-cargavirtual,
.paymentmethod-cargavirtual.paymentmethod-large {
	background-position: 0 -849px;
}

.paymentmethod-cordial {
	background-position: 0 -898px;
}

.paymentmethod-cordial.paymentmethod-large {
	background-position: 0 -900px;
}

.paymentmethod-cmr,
.paymentmethod-cmr.paymentmethod-large {
	background-position: 0 -949px;
}

.paymentmethod-cordobesa,
.paymentmethod-cordobesa.paymentmethod-large {
	background-position: 0 -998px;
}

.paymentmethod-mercadopago_cc {
	background-position: 0 -1047px;
}

.paymentmethod-mercadopago_cc.paymentmethod-large {
	background-position: 0 -1049px;
}

.paymentmethod-consumer_credits {
	background-position: 0 -1097px;
}

.paymentmethod-consumer_credits.paymentmethod-large {
	background-position: 0 -1098px;
}

.paymentmethod-maestro {
	background-position: 0 -1197px;
}

.paymentmethod-maestro.paymentmethod-large {
	background-position: 0 -1199px;
}

.paymentmethod-patagonia365 {
	background-position: 0 -1346px;
}

.paymentmethod-patagonia365.paymentmethod-large {
	background-position: 0 -1346px;
}

.paymentmethod-nevada {
	background-position: 0 -1396px;
}

.paymentmethod-nevada.paymentmethod-large {
	background-position: 0 -1393px;
}

.paymentmethod-cobroexpress {
	background-position: 0 -1448px;
}

.paymentmethod-cobroexpress.paymentmethod-large {
	background-position: 0 -1444px;
}

[class*="paymentmethod"] {
	background-image: url("https://http2.mlstatic.com/secure/org-img/ui/payment-methods/1.7.6/ar/payment-methods-default.png");
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
	[class*="paymentmethod"] {
		background-image: url("https://http2.mlstatic.com/secure/org-img/ui/payment-methods/1.7.6/ar/payment-methods-default@2x.png");
		background-size: 72px 1498px;
	}
}

.paymentmethod-account_money {
	height: 20px;
	width: 72px;
}

.paymentmethod-visa {
	width: 39px;
	height: 16px;
}

.paymentmethod-debvisa {
	width: 58px;
	height: 16px;
}

.paymentmethod-master {
	height: 26px;
	width: 30px;
}

.paymentmethod-debmaster {
	height: 26px;
	width: 49px;
}

.paymentmethod-amex {
	height: 17px;
	width: 17px;
}

.paymentmethod-diners {
	height: 16px;
	width: 62px;
}

.paymentmethod-bitcoin {
	height: 16px;
	width: 62px;
}

.paymentmethod-naranja {
	height: 22px;
	width: 16px;
}

.paymentmethod-nativa {
	height: 15px;
	width: 36px;
}

.paymentmethod-tarshop {
	width: 27px;
	height: 16px;
}

.paymentmethod-cencosud {
	height: 17px;
	width: 26px;
}

.paymentmethod-cabal {
	height: 17px;
	width: 17px;
}

.paymentmethod-debcabal {
	height: 17px;
	width: 17px;
}

.paymentmethod-argencard {
	height: 18px;
	width: 17px;
}

.paymentmethod-pagofacil,
.paymentmethod-redlink {
	height: 20px;
	width: 19px;
}

.paymentmethod-rapipago {
	height: 11px;
	width: 56px;
}

.paymentmethod-banelco {
	width: 46px;
	height: 16px;
}

.paymentmethod-bapropagos {
	width: 54px;
	height: 24px;
}

.paymentmethod-cargavirtual {
	height: 20px;
	width: 20px;
}

.paymentmethod-cordial {
	height: 21px;
	width: 30px;
}

.paymentmethod-cmr {
	height: 19px;
	width: 26px;
}

.paymentmethod-cordobesa {
	height: 10px;
	width: 40px;
}

.paymentmethod-mercadopago_cc {
	height: 20px;
	width: 30px;
}

.paymentmethod-consumer_credits {
	height: 20px;
	width: 74px;
}

.paymentmethod-maestro {
	height: 25px;
	width: 30px;
}

.paymentmethod-patagonia365 {
	height: 10px;
	width: 73px;
}

.paymentmethod-nevada {
	height: 20px;
	width: 25px;
}

.paymentmethod-cobroexpress {
	height: 16px;
	width: 58px;
}

.payment-icon {
	display: inline-block;
	margin: 1px 2px 5px 2px;
	overflow: hidden;
	vertical-align: middle;
	white-space: nowrap;

	img {
		height: 27px;
		width: auto;
		background-repeat: no-repeat;
		text-indent: 100%;
	}
}

.payment-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
